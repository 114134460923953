import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/home/runner/work/design/design/node_modules/@primer/gatsby-theme-doctocat/src/components/layout.js";
import ComponentLayout from '~/src/layouts/component-layout';
import { AccessibilityLink } from '~/src/components/accessibility-link';
export const _frontmatter = {};
const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};
const CustomVideoPlayer = makeShortcode("CustomVideoPlayer");
const layoutProps = {
  _frontmatter
};
const MDXLayout = ComponentLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">





    <CustomVideoPlayer width="960" loop autoPlay src="https://github.com/user-attachments/assets/5338bda1-aca0-4be5-8d8b-d849725d30de" mdxType="CustomVideoPlayer" />
    <h2>{`Usage`}</h2>
    <p>{`Skeleton avatars may be used to improve perceived performance by rendering a rough representation of an avatar in a block of content that is still loading.`}</p>
    <h2>{`Options`}</h2>
    <p>{`Skeleton avatars support the same variants as the `}<a parentName="p" {...{
        "href": "/components/avatar"
      }}>{`avatar`}</a>{` component.`}</p>
    <h2>{`Acccessibility`}</h2>
    <p>{`Refer to the loading pattern `}<a parentName="p" {...{
        "href": "/ui-patterns/loading#accessibility"
      }}>{`accessibility guidelines`}</a>{`. Specifically, the secion about `}<a parentName="p" {...{
        "href": "/ui-patterns/loading#avoiding-over-announcing-loading-states"
      }}>{`avoiding over-announcing loading states`}</a>{`.`}</p>
    <h3>{`Known accessibility issues (GitHub staff only)`}</h3>
    <AccessibilityLink label="SkeletonAvatar" mdxType="AccessibilityLink" />
    <h2>{`Related links`}</h2>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "/ui-patterns/loading"
        }}>{`Loading`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "/components/avatar"
        }}>{`Avatar`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "/components/skeleton-box"
        }}>{`Skeleton box`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "/components/skeleton-text"
        }}>{`Skeleton text`}</a></li>
    </ul>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      